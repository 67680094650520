import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { BasicStatsItem } from './index.styles'
import { Text } from '../text.component'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { IPSDItem } from '../../interfaces/snp.interface';
import { ExpandCard } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';
import { getLastWeekdayDate } from '../../utils/helpers.utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const firstKeys = ['Здравоохранение', 'ИКИ', 'Дороги', 'Образование', 'Связь', 'Культура и спорт']

interface IPSD {
  data: IPSDItem[];
  handleFullScreen: (obj: any) => void;
  show: boolean;
}

const PSD: FC<IPSD> = ({ data, handleFullScreen, show }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<any>(null);
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [maxSum, setMaxSum] = useState<number>(100);

  const [visibleDatasets, setVisibleDatasets] = useState<any>({
    0: true,
    1: true,
  });

  const chartContainerRef = useRef(null);

  const sumInvestors = (data: IPSDItem) => {
    return data.governmentInvestorsCount + data.quasiGovernmentInvestorsCount;
  }

  useEffect(() => {
    const inFirstKeys: any = [];
    const notInFirstKeys: any = [];

    data.filter(item => +sumInvestors(item) > 0).forEach(item => {
      if (firstKeys.includes(item.category)) {
        inFirstKeys.push(item);
      } else {
        notInFirstKeys.push(item);
      }
    });

    inFirstKeys.sort((a: any, b: any) => b.totalCount - a.totalCount);
    notInFirstKeys.sort((a: any, b: any) => b.totalCount - a.totalCount);

    const filteredFirst = inFirstKeys.filter((item: any) => item.governmentInvestorsCount > 0 || item.quasiGovernmentInvestorsCount > 0);

    const sortedAndFiltered = [...filteredFirst, ...notInFirstKeys].slice(0, 6).sort((a: any, b: any) => +sumInvestors(b) - +sumInvestors(a));

    const datasets = [
      {
        label: t('PSD.governmentInvestors'),
        data: sortedAndFiltered.map((item) => item.governmentInvestorsCount),
        backgroundColor: '#92D050',
        barThickness: 7,
        borderRadius: 10,
      },
      {
        label: t('PSD.quasiGovernmentInvestors'),
        data: sortedAndFiltered.map((item) => item.quasiGovernmentInvestorsCount),
        backgroundColor: '#226AC3',
        barThickness: 7,
        borderRadius: 10,
      },
    ]

    const sums = sortedAndFiltered.map((_, index) => {
      return datasets.reduce((sum, dataset) => sum + dataset.data[index], 0);
    });

    const maxSumVal = Math.max(...sums);
    sums.length > 0 && maxSumVal !== 0 && setMaxSum(maxSumVal);

    const processedLabels = sortedAndFiltered.map((item) => item.category.split(' ')).map((item: any) => item.includes('Культура') ? ['Культура', 'и спорт'] : item);

    setChartData({
      labels: processedLabels,
      datasets,
    });
  }, [data])


  const handleResize = () => {
    setChartOptions({
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rect',
          },
          display: false,
        },
        datalabels: {
          display: function (context: any) {
            const datasetIndex = context.datasetIndex;
            const visibleDatasetsArray = Object.keys(visibleDatasets)
              .filter((key) => visibleDatasets[key] === true)
              .map(Number);
            const lastVisibleDatasetIndex = Math.max(...visibleDatasetsArray);
            return datasetIndex === lastVisibleDatasetIndex;
          },
          anchor: 'end',
          align: 'end',
          formatter: function (value: any, context: any) {
            const visibleDatasetsArray = Object.keys(visibleDatasets)
              .filter((key) => visibleDatasets[key] === true)
              .map(Number);
            if (visibleDatasetsArray.length > 0) {
              const total = visibleDatasetsArray.reduce((sum, datasetIndex) => {
                return sum + context.chart.data.datasets[datasetIndex].data[context.dataIndex];
              }, 0);
              return total;
            }
            return '';
          },
          color: theme === Theme.LIGHT ? '#118F84' : '#ffc803', 
          font: {
            size: 16,
            weight: 'bold'
          }
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          display: false,
          grid: {
            display: false
          },
          stacked: true,
          min: 0,
          max: maxSum * 1.25,
        },
        y: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            color: theme === Theme.LIGHT ? '#202224' : '#fff',
            font: {
              size: 8,
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: true
    });
  }

  useEffect(() => {
    handleResize();
  }, [chartData]);

  useEffect(() => {
    const resizeChart = () => {
      setTimeout(() => {
        chartRef.current?.resize();
      }, 100);
    };

    resizeChart();
  }, [show]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (chartRef.current) {
        chartRef.current.resize(); 
        chartRef.current.update();
      }
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);


  return (
    maxSum !== Infinity && chartData && chartOptions && <BasicStatsItem id="psd">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`PSD.title`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>на {getLastWeekdayDate()}</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'PSD' })} />
          </span>
        </div>
      </div>
      <div className="body" style={{ paddingRight: 0 }}>
        <div className="df">
          <div className='chart-container' ref={chartContainerRef} >
            <Bar data={chartData} options={chartOptions} ref={chartRef} />
          </div>
        </div>
      </div>

    </BasicStatsItem >
  )
}

export default PSD