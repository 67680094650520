import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../contexts/theme.context';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { ExpandCard } from '../../assets/icons';
import { isRegion, months, OBLAST_KATO } from '../../utils/helpers.utils';
import { ITaxStat } from '../../interfaces/snp.interface';

interface IProps {
  handleFullScreen: (obj: any) => void,
  data: ITaxStat[],
  kato: number
}

const tabs = ['REGIONAL_BUDGET', 'PROPER_REGIONAL_BUDGET']

const TaxComponent: FC<IProps> = ({ handleFullScreen, data, kato }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext)
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredTabs, setFilteredTabs] = useState(tabs);

  const findData = (tab: string): ITaxStat => {
    return data.find((item: any) => item.type === tab) || {} as ITaxStat
  }

  const getType = (type: string) => {
    if (kato) {
      if (type === 'REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'DISTRICT_BUDGET';
        } else if (!isRegion(+kato)) {
          return 'SO_BUDGET';
        } else {
          return 'REGIONAL_BUDGET';
        }
      } else if (type === 'PROPER_REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'PROPER_DISTRICT_BUDGET';
        } else {
          return 'PROPER_REGIONAL_BUDGET';
        }
      }
    }
  }

  useEffect(() => {
    if (kato) {
      if (!isRegion(+kato)) {
        setFilteredTabs(['REGIONAL_BUDGET'])
      } else {
        setFilteredTabs(tabs)
      }
    }
  }, [kato])

  return (
    <BasicStatsItem theme={theme} id="tax">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`district-income.oblast`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>на 1 {months[new Date().getMonth()]} 2024 г.</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'DistrictIncome' })} />
          </span>
        </div>
      </div>

      <div className="body">
        <div className="tabs">
          {filteredTabs.map((tab) => <Text
            onClick={() => setSelectedTab(tab)}
            fontSize='0.5rem'
            fontWeight={selectedTab === tab ? 700 : 400}
            color={`${selectedTab === tab ? 'var(--text-color)' : 'var(--gray)'}`}
            className={`${selectedTab === tab && 'selected'}`}
          >
            {t('taxStats.' + getType(tab))}
          </Text>)}
        </div>
        <div className="tax-grid">
          {/* ROW 1 */}
          <Text className='header' fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>Доходы</Text>
          <Text className='header' fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>План</Text>
          <Text className='header' fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>Факт</Text>
          <div className='merged header'>
            <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>
              Исполнение
            </Text>
            <Text className="lower">
              <Text fontWeight={400} color="var(--text-color)" fontSize='0.5625rem'>на плановый <br /> период</Text>
              <Text fontWeight={400} color="var(--text-color)" fontSize='0.5625rem'>за год</Text>
            </Text>
          </div>

          {/* ROW 2 */}
          <Text fontWeight={700} color="var(--text-color)" fontSize='0.75rem' style={{ minWidth: "100%" }}>{t('taxStats.total')} </Text>
          {(['annual', 'fact', 'planPercent', 'annualPercent'] as (keyof ITaxStat)[]).map((item: any) => <div className="wrap">
            <Text className='wrap__item' fontWeight={700} color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat] || 0}{item.includes('Percent') && '%'}</Text>
          </div>)}

          {/* ROW 3 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>{t('taxStats.tax')}</Text>
          {(['taxRevenuesAnnual', 'taxRevenuesFact', 'taxRevenuesPlanPercent', 'taxRevenuesAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) => <div className="wrap">
            <Text className='wrap__item' fontWeight={400} color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat] || 0}{item.includes('Percent') && '%'}</Text>
          </div>)}

          {/* ROW 4 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>{t('taxStats.nonTax')}</Text>
          {(['nonTaxRevenuesAnnual', 'nonTaxRevenuesFact', 'nonTaxRevenuesPlanPercent', 'nonTaxRevenuesAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) => <div className="wrap">
            <Text className='wrap__item' fontWeight={400} color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat] || 0}{item.includes('Percent') && '%'}</Text>
          </div>)}

          {/* ROW 5 */}
          <Text fontWeight={400} color="var(--text-color)" fontSize='0.75rem'>{t('taxStats.fixedAssets')}</Text>
          {(['fixedAssetsSaleAnnual', 'fixedAssetsSaleFact', 'fixedAssetsSalePlanPercent', 'fixedAssetsSaleAnnualPercent'] as (keyof ITaxStat)[]).map((item: any) => <div className="wrap">
            <Text className='wrap__item' fontWeight={400} color="var(--primary-color)" fontSize='0.75rem'>{findData(selectedTab)?.[item as keyof ITaxStat] || 0}{item.includes('Percent') && '%'}</Text>
          </div>)}

        </div>
      </div>
    </BasicStatsItem>
  )
}

export default TaxComponent