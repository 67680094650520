import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExpandCard } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';
import { IPopulationDynamic } from '../../interfaces/snp.interface';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

interface IProps {
  chartData: IPopulationDynamic[];
  handleFullScreen: (obj: any) => void;
  show: boolean;
}

const PopulationDynamic: FC<IProps> = ({ chartData, handleFullScreen, show }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext)
  const chartRef = useRef<any>(null);
  const currentYear = new Date().getFullYear();
  const [chartInfo, setChartInfo] = useState<any>(null);

  const getColor = (year: number, isTicks = false) => {
    if (year === currentYear) {
      return theme === Theme.LIGHT ? '#118F84' : '#ffc803';
    } if (year < currentYear) {
      return theme === Theme.LIGHT ? isTicks ? '#98A3A9' : "#92D050" : '#fff';
    } else {
      return theme === Theme.LIGHT ? isTicks ? '#606367' : "#226AC3" : 'rgba(255, 255, 255, 0.5)';
    }
  };

  const getChartInfo = () => {
    const years = chartData?.map((item: IPopulationDynamic) => item.year);
    const data: any = {
      labels: years,
      datasets: [
        {
          label: 'Население',
          data: chartData.map((item: IPopulationDynamic) => (item.value)),
          borderColor: years.map(year => getColor(year)),
          backgroundColor: years.map(year => getColor(year)),
          pointRadius: 4,
          pointBackgroundColor: years.map(year => getColor(year)),
          borderDash: years.map(year => year < currentYear ? [] : [5, 5]),
          segment: {
            borderDash: (ctx: any) => +ctx.p0DataIndex >= 3 ? [3, 3] : [],
            borderColor: (ctx: any) => ctx.p0DataIndex < 3 ? '#92D050' : '#226AC3',
          },
          spanGaps: true
        },
      ],
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          grid: {
            display: false,
            drawTicks: false,
          },
          position: 'top',
          ticks: {
            color: years.map(year => getColor(year, true)),
            align: 'center',
            baseLine: 'middle',
            font: {
              size: years.map(year => year === currentYear ? 14 : 12),
              weight: years.map(year => year === currentYear ? 700 : 400),
            },
          },
          offset: true,
        },
        y: {
          grid: {
            color: '#c6cacc',
            beginAtZero: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 4,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: years.map(year => getColor(year)),
          formatter: function (value: any, context: any) {
            const coef = context.chart.data.datasets[0].data[3] >= 1000 ? 1000 : 1
            return coef === 1 ? value : (value / coef).toFixed(1).toLocaleString();
          },
          font: years.map(year => ({
            size: year === currentYear ? 14 : 12,
            weight: year === currentYear ? 700 : 400,
          })),
          textAlign: 'center',
          clamp: true,
          anchor: 'start',
          align: (context: any) => {
            const index = context.dataIndex;
            const datasets = context.chart.data.datasets[0].data;
            const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
            return datasets[index] <= average ? 'end' : 'start';
          },
        }
      },
      layout: {
        backgroundColor: '#F8F7EF',
      },
    };
    setChartInfo({ data, options });
  }

  useEffect(() => {
    getChartInfo();
  }, [theme, chartData])

  return (
    chartInfo && <BasicStatsItem id="dynamic">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`population-dynamic.title`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>{currentYear >= 1000 ? 'тысяч человек' : 'человек'}</Text>

        </div>
        <div className="right">
          <span className="unit-container">
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'population-dynamic' })} />
          </span>
        </div>
      </div>
      <div className="body">
        <div className="line-chart-container">
          {chartInfo && <Line ref={chartRef} data={chartInfo.data} options={chartInfo.options} plugins={[ChartDataLabels]} />}
        </div>
      </div>
    </BasicStatsItem >
  )
};

export default PopulationDynamic;
