import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { Text } from '../../text.component'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IMCB, IScreeningMCBCreditSupport } from '../../../interfaces/snp.interface';
import { mcbSubjectsOptions, registeredSubjectsOptions } from './chart-options';
import { creditSupportKeys } from '../../../pages/admin/mcb.page';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const MCB_header = [
  'Категория фин. поддержки',
  'кол-во проектов',
  'сумма, млн. тг',
]

interface IProps {
  data: IMCB
}

const MCBTab: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const [mcbSubjects, setMcbSubjects] = useState<any>(null);
  const [registeredSubjects, setRegisteredSubjects] = useState<any>(null);
  const [creditSupport, setCreditSupport] = useState<IScreeningMCBCreditSupport | null>(null);

  const buildGraphs = () => {
    if (data) {

      setMcbSubjects({
        labels: [['Количество', 'действующих', 'субъектов МСБ'], ['Количество', 'зарегистрированных', 'субъектов МСБ']],
        datasets: [
          {
            label: 'Всего',
            data: [+(data.mcbSubjects?.total || 0), null,],
            backgroundColor: '#118F84',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: 'ТОО',
            data: [null, +(data.mcbSubjects?.TOO || 0)],
            backgroundColor: '#92D050',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: 'ИП',
            data: [null, +(data.mcbSubjects?.IP || 0)],
            backgroundColor: '#5BC7D9',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: 'СПК',
            data: [null, +(data.mcbSubjects?.SPK || 0)],
            backgroundColor: '#dedede',
            borderRadius: 5,
            barThickness: 40,
          },
          {
            label: 'КХ',
            data: [null, +(data.mcbSubjects?.TOO || 0)],
            backgroundColor: '#2E6CBD',
            borderRadius: 5,
            barThickness: 40,
          },
        ],
      })

      setRegisteredSubjects({
        labels: ['Юридические', 'Сельхоз', 'Строительные', ['Розничная', 'торговля'], ['Услуги', 'печати'], ['Финансовая', 'среда'], ['Коммерческие', 'услуги']],
        datasets: [
          {
            label: 'Субъекты МСБ',
            data: [
              +(data.registeredSubjects?.juridical || 0),
              +(data.registeredSubjects?.agricultural || 0),
              +(data.registeredSubjects?.construction || 0),
              +(data.registeredSubjects?.retail || 0),
              +(data.registeredSubjects?.printing || 0),
              +(data.registeredSubjects?.finance || 0),
              +(data.registeredSubjects?.commerce || 0)],
            borderColor: '#009187',
            backgroundColor: '#009187',
            pointBackgroundColor: '#009187',
            borderRadius: 5,
            barThickness: 42,
          }
        ]
      })

      setCreditSupport({
        grant_projects: data.creditSupport?.grant_projects || '0',
        grant_sum: data.creditSupport?.grant_sum || '0',
        subsidizing_projects: data.creditSupport?.subsidizing_projects || '0',
        subsidizing_sum: data.creditSupport?.subsidizing_sum || '0',
        guarantee_projects: data.creditSupport?.guarantee_projects || '0',
        guarantee_sum: data.creditSupport?.guarantee_sum || '0'
      })
    }
  }

  useEffect(() => {
    buildGraphs()
  }, [data])

  return (
    <div className='grid c-2 screening human-resources'>
      {
        data.mcbSubjects && mcbSubjects && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Количество МСБ</Text>
            </div>
          </div>
          {data.mcbSubjects && mcbSubjects && <div className="body">
            <Bar data={mcbSubjects} options={mcbSubjectsOptions} />
          </div>}
        </BasicStatsItem>}
      {data.registeredSubjects && registeredSubjects && <BasicStatsItem>
        <div className="title">
          <div className="left">
            <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Зарегистрированные субъекты МСБ</Text>
          </div>
        </div>
        <div className="body">
          <Bar data={registeredSubjects} options={registeredSubjectsOptions} />
        </div>
      </BasicStatsItem>
      }


      {
        data.totalSum && <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Общая сумма полученной финансово-кредитной поддержки субъектами МСБ с учетом приобретенной в лизинг техники</Text>
            </div>
          </div>
          <div className="body mcb-body">
            <div className="value">
              <Text color='var(--primary-color)' fontWeight={700} fontSize='4.25rem'>{data.totalSum || 0}</Text>
              <Text color='#AEB0AF' fontWeight={500} fontSize='3rem'>млрд тенге</Text>
            </div>
          </div>
        </BasicStatsItem>
      }

      {
        <BasicStatsItem>
          <div className="title">
            <div className="left">
              <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>Финансово-кредитная поддержка</Text>
            </div>
          </div>
          <div className="body ">
            <table className="mcb-table">
              <thead>
                <tr>
                  {
                    MCB_header.map((item) => (
                      <th key={item}>
                        <Text fontSize='0.75rem' color='#606367' fontWeight={400}>{item}</Text>
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  creditSupport && creditSupportKeys.map((key) => (
                    <tr key={key}>
                      <td>
                        <Text fontSize='0.875rem' color='#202224' fontWeight={400}>{t('screening-page.credit-support.' + key)}</Text>
                      </td>
                      <td>
                        <Text fontSize='1.875rem' color='var(--primary-color)' fontWeight={700}>{creditSupport[`${key}_projects` as keyof IScreeningMCBCreditSupport]}</Text>
                      </td>
                      <td>
                        <Text fontSize='1.875rem' color='var(--primary-color)' fontWeight={700}>{creditSupport[`${key}_sum` as keyof IScreeningMCBCreditSupport]}</Text>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </BasicStatsItem>}
    </div>
  )
}

export default MCBTab