export const allPSDsOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    x: {
      stacked: true,
      display: false,
      drawTicks: false,
      max: (context: any) => {
        const datasets: any = context.chart.data.datasets;
        const maxSum: any = Math.max(...context.chart.data.labels.map((_: any, index: any) =>
          datasets.reduce((sum: any, dataset: any) => sum + (dataset.data[index] || 0), 0)
        ));

        return maxSum * 1.275;
      },
      grid: {
        drawTicks: false,
      }
    },
    y: {
      stacked: true,
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        beginAtZero: true,
        font: {
          size: 10 ,
        }
      }
    }
  },

}

export const topFiveByActivityTypeOptions: any = {

}


export const topFiveByObjectTypeOptions: any = {

}

export const topFiveByInvestmentSourceOptions: any = {

}

export const topTenPSDCustomersOptions: any = {

}

export const PSDByObjectCategoryOptions: any = {

}
