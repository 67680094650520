import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { tabs } from '../../constants/navlinks.constant'

import { TabsContainer, Tab } from './admin-page.styles'
import { isRegion, OBLAST_KATO } from '../../utils/helpers.utils'
import styled from 'styled-components'

interface IProps {
  selectedTab: string,
  onSelect: (tab: string) => void,
  kato: string
}

const ScrollWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  animation: fadeIn 0.5s;
`;

const ArrowButton = styled.div`
  background: var(--lightYellow);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--borderGray);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: middle;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  color: var(--shuttleGray);
  &:disabled {
    cursor: not-allowed;
    color: var(--borderGray);
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 0;
`;

const RightArrow = styled(ArrowButton)`
  right: 0;
`;



const Tabs: React.FC<IProps> = ({ selectedTab, onSelect, kato }) => {
  const { t } = useTranslation()
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [filteredTabs, setFilteredTabs] = useState<string[]>([]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 400;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 400;
    }
  };

  useEffect(() => {
    if (kato) {
      if (isRegion(+kato)) {
        setFilteredTabs(+kato === OBLAST_KATO ? tabs.filter(item => !['roads-condition'].includes(item)) : tabs)
      } else {
        setFilteredTabs(['general_info', 'self-sufficiency', 'population-dynamic', 'infrastructure', 'tax', 'budget'])
      }
    }
  }, [kato])

  return (
    <ScrollWrapper>
      <LeftArrow onClick={scrollLeft}>&#8249;</LeftArrow>
      <TabsContainer ref={scrollContainerRef}>
        {
          filteredTabs.map(tab =>
            <Tab key={tab} isSelected={selectedTab === tab} onClick={() => onSelect(tab)}>
              {t(`navLinks.${tab}`)}
            </Tab>)
        }
      </TabsContainer>
      <RightArrow onClick={scrollRight}>&#8250;</RightArrow>
    </ScrollWrapper>

  )
}

export default Tabs