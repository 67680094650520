import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { IBudgetItem, IInvestmentProject } from '../../interfaces/snp.interface';
import { getBudgetTotals } from '../../utils/helpers.utils';
import { ExpandCard } from '../../assets/icons';


const months = [
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];
interface IProps {
  data: IInvestmentProject[],
  handleFullScreen: (obj: any) => void;
  budgetData: IBudgetItem;
}

const InvestProjects: FC<IProps> = ({ data, handleFullScreen, budgetData }) => {
  const { t } = useTranslation();
  const [budgetTotals, setBudgetTotals] = useState<IBudgetItem | null>(null);

  useEffect(() => {
    if (budgetData) {
      setBudgetTotals({
        ...budgetData,
        totalBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'totalBudgetMonthlyProcessing') || 0,
        curBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'curBudgetMonthlyProcessing') || 0,
        devBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'devBudgetMonthlyProcessing') || 0,
        curBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'curBudgetAnnualProcessing') || 0,
        totalBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'totalBudgetAnnualProcessing') || 0,
        devBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'devBudgetAnnualProcessing') || 0,
        totalBudgetMonthlyPlan: +getBudgetTotals(budgetData, 'totalBudgetMonthlyPlan') || 0,
        totalBudgetMonthlyFact: +getBudgetTotals(budgetData, 'totalBudgetMonthlyFact') || 0,
        totalBudgetAnnualPlan: +getBudgetTotals(budgetData, 'totalBudgetAnnualPlan') || 0
      })
    }
  }, [budgetData])

  return (
    budgetTotals && <BasicStatsItem id="invest-projects">
      <div className="title">
        <div className="left">
          <Text color='var(--primary-color)' fontWeight={500} fontSize='0.875rem'>{t(`budgetInvestmentProjectStats.osvoenie`)}</Text>
          <Text color='#818689' fontWeight={400} fontSize='0.75rem'>млн. тенге</Text>
        </div>

        <div className="right" style={{ display: 'flex', alignItems: 'center' }}>
          <span className='unit-container'>
            <ExpandCard onClick={() => handleFullScreen && handleFullScreen({ isOpen: true, type: 'InvestProjects' })} />
          </span>
        </div>

      </div>
      <div className="body">
        <div className="budget-grid">
          <div className='budget-grid__header'></div>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>План </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Факт </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Освоение </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>План</Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Освоение</Text>

          <div className='budget-grid__header bordered'></div>
          <Text className='budget-grid__header span-3 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>на 1 {months[new Date().getMonth()]} </Text>
          <Text className='budget-grid__header span-2 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>на 2024 </Text>
          <div className='bordered'></div>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Всего</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.totalBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.totalBudgetAnnualProcessing}%</Text>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Текущий <br /> бюджет</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.curBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.curBudgetAnnualProcessing}%</Text>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Бюджет  <br /> развития</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.devBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.devBudgetAnnualProcessing}%</Text>
        </div>
      </div>
    </BasicStatsItem>
  )
}

export default InvestProjects